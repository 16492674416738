<!-- Toolbar -->
<nav
  class="
    navbar navbar-expand-md navbar-static
    ms-navbar ms-navbar-primary
    navbar-mode bg-dark
  "
  role="banner"
>
  <div class="container container-full">
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html">
        <!-- <img src="assets/img/demo/logo-navbar.png" alt=""> -->
        <img
        width="40"
        alt="Angular Logo"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg=="
      />
      </a>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav">
        <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
         <h5><a class="text-white" routerLink="/members">Members List</a></h5>
        </li>
      </ul>
    </div>


  </div>
</nav>
