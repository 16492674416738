<div class="container">
  <br />
  <div class="row">
    <div class="col-md-11">
      <div class="card">
        <div class="card-header bg-primary text-white">
          <h3>{{ pageTitle }}</h3>
        </div>
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-md-3" *ngIf="member.picture.large">
              <img
                class="center-block img-responsive"
                [style.height.px]="200"
                [style.margin.px]="2"
                [src]="member.picture.large"
              />
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-3">First Name:</div>
                <div class="col-md-6">{{ member.name.first }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">Last Name:</div>
                <div class="col-md-6">{{ member.name.last }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">Street:</div>
                <div class="col-md-6">
                  {{
                    member.location.street.number +
                      " " +
                      member.location.street.name
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">City:</div>
                <div class="col-md-6">{{ member.location.city }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">State:</div>
                <div class="col-md-6">
                  {{ member.location.state }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Post Code:</div>
                <div class="col-md-6">{{ member.location.postcode }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">Email:</div>
                <div class="col-md-6">{{ member.email }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">Date of Birth:</div>
                <div class="col-md-6">
                  {{ member.dob.date | date: "longDate" }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Phone:</div>
                <div class="col-md-6">{{ member.phone }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
