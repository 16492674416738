<br>
<div class="card">
  <div class="card-header bg-primary text-white">
   <h2>{{ pageTitle }}</h2>
  </div>
  <div class="card-body">

    <div class="row">
      <div class="mx-auto">
        <img src="./assets/images/member-stamp.jpg" alt="" />
      </div>
    </div>
  </div>
</div>
