<br>
<div class="container">
  <div class="row">
    <div class="col-md-5" align="center">
      <div class="card">
        <div class="card-header bg-primary text-white">
          <h3 class="card-title"> {{ pageTitle }} </h3>
          <div class="form-group">
            <input type="text" class="form-control mb-4" placeholder="Search"
            [(ngModel)]="query" id="listSearch">
          </div>
        </div>
        <div class="card-body">
         <input type="text" value="{{members.length}}" hidden="true" >
          <div class="table table-hover">
            <div class="table" *ngIf="members?.length" >
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Age</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let member of members | searchFilter: query" >
                  <td>{{ member.name.first }} </td>
                  <td>{{ member.name.last }} </td>
                  <td>{{ member.dob.age }} </td>
                  <td><img [src]= member.picture.thumbnail></td>
                  <td><button type="button" class="btn btn-success" [value]="member.email"
                    (click) = 'fetchProfile(member.email)' >Profile</button></td>
                </tr>
              </tbody>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

